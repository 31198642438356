<template>
  <div class="b2b2b-shopping-mall">
    <div class="wrap-content">
      <div class="nav_box">
        <div class="top-title">
          <div class="left">
            <img :src="navImg" alt="" />
            <span>深圳</span>
          </div>
          <div class="search">
            <img :src="navImg3" alt="" />
            <span>请输入您要搜索的商品</span>
          </div>
          <div class="right">
            <img :src="navImg2" alt="" />
          </div>
        </div>
        <!-- 分类导航 -->
        <div class="classify_box hide-scrollbar">
          <div
            class="classify_item"
            v-for="(item, index) in classifyList"
            :key="index"
          >
            {{ item }}
          </div>
          <div class="line"></div>
        </div>
      </div>

      <div class="content-box hide-scrollbar">
        <!-- 轮播图 -->
        <div class="banner_box">
          <img :src="bannerImg" alt="" />
          <!-- 小圆点 -->
          <div class="round">
            <div
              class="round_item"
              v-for="(item, index) in 5"
              :key="index"
            ></div>
          </div>
          <!-- 悬浮消息 -->
          <div class="tips_box">
            <img :src="defaultHead" alt="" />
            <span>深圳123****9743刚成交3687元</span>
          </div>
        </div>
        <!-- 分类 -->
        <div class="activity-alassify">
          <div
            class="activity-alassify_item"
            v-for="(item, index) in activityAlassifyList"
            :key="index"
          >
            <div class="img_box">
              <img :src="item.bgImg" alt="" />
              <img :src="item.image" alt="" />
            </div>
            <span>{{ item.title }}</span>
          </div>
          <img :src="activitIcon" alt="" />
          <img :src="activitIcon2" alt="" />
          <img :src="hotIcon" alt="" />
        </div>
        <!-- 头条 -->
        <div class="headlines_box">
          <div class="top">
            <span>丝路头条</span>
            <img :src="newsIcon" alt="" />
            <span>7-18新品到店，优惠多多，快来抢购吧！</span>
          </div>
          <div class="bottom_box">
            <div class="left">
              <div class="title">
                <img :src="newsIcon2" alt="" />
                <span>选择凡丝路六大理由</span>
              </div>
              <div class="list_box">
                <div
                  class="list_item"
                  v-for="(item, index) in contentList"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
            </div>
            <div class="right">
              <div class="top">
                <img :src="priceIcon" alt="" />
                <span>免费旅游</span>
              </div>
              <img :src="newsIcon4" alt="" />
              <div class="text_box">新人首单满89送1000积分</div>
            </div>
          </div>
        </div>

        <!-- 限时秒杀 -->
        <div class="seckill_box">
          <div class="content">
            <div class="top">
              <div class="left">
                <span>限时秒杀</span>
                <img :src="newsIcon3" alt="" />
                <div class="time">
                  <span class="time_item">00</span><i>天</i>
                  <span class="time_item">05</span><i>时</i>
                  <span class="time_item">24</span><i>分</i>
                  <span class="time_item">38</span><i>秒</i>
                </div>
              </div>
              <div class="right">
                <span>更多</span>
                <img :src="arrowFr" alt="" />
              </div>
            </div>
            <div class="bottom">
              <div
                class="goods_item"
                v-for="(item, index) in seckillGoodsList"
                :key="index"
              >
                <img :src="item.image" alt="" />
                <span>{{ item.price }}</span>
                <span>{{ item.originalPrice }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 商品展示列表 -->
        <div class="goods_box">
          <div class="top">
            <div class="title">大牌甄选</div>
            <!-- 分类导航 -->
            <div class="classify_box hide-scrollbar">
              <div
                class="classify_item"
                v-for="(item, index) in classifyList"
                :key="index"
              >
                {{ item }}
              </div>
              <div class="line"></div>
            </div>
            <div class="classify_tag">
              <div
                class="classify_tag_item"
                v-for="(item, index) in classifyTag"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </div>

          <div class="goods_list">
            <div
              class="goods_item_show"
              v-for="(item, index) in goodsList"
              :key="index"
            >
              <div class="title">{{ item.title }}</div>
              <img :src="item.goodsImg" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="footer">
        <tabbarBox :tabbarData="tabbarData" :tabbarNum="5"></tabbarBox>
      </div>

      <!-- 置顶 -->
      <div class="float_box">
        <img :src="floatIcon" alt="" />
      </div>
    </div>
  </div>
</template>

<script setup>
import tabbarBox from "../tabbar/index.vue";
// 顶部盒子图片
const navImg = require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_top1.png");
const navImg2 = require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_top2.png");
const navImg3 = require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_search.png");

// 顶部分类导航
const classifyList = [
  "首页",
  "食品饮料",
  "个护",
  "护肤品",
  "彩妆",
  "手机",
  "厨具",
];

// banner
const bannerImg = require("@/assets/images/imitatepage/b2b2b-shopping-mall/banner.png");
// 默认头像
const defaultHead = require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_head.png");

// 分类
const activityAlassifyList = [
  {
    title: "今日爆款",
    bgImg: require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_classify06.png"),
    image: require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_classify01.png"),
  },
  {
    title: "销量排行榜",
    bgImg: require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_classify07.png"),
    image: require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_classify02.png"),
  },
  {
    title: "大牌甄选",
    bgImg: require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_classify08.png"),
    image: require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_classify03.png"),
  },
  {
    title: "应季畅销",
    bgImg: require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_classify09.png"),
    image: require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_classify04.png"),
  },
  {
    title: "来拼团",
    bgImg: require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_classify10.png"),
    image: require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_classify05.png"),
  },
];

const activitIcon = require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_classify11.png");
const activitIcon2 = require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_classify12.png");
const hotIcon = require("@/assets/images/imitatepage/b2b2b-shopping-mall/hot_icon.png");

// 头条图片
const newsIcon = require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_news.png");
const newsIcon2 = require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_news2.png");
const newsIcon3 = require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_news3.png");
const newsIcon4 = require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_news4.png");
const priceIcon = require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_price.png");
const arrowFr = require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_arrow.png");

//图片列表
const contentList = [
  "1、100%正品 ",
  "4、礼品多多",
  "2、低价回馈",
  "5、同城服务",
  "3、免费旅游",
  "6、信息分享",
];

// 秒杀商品
const seckillGoodsList = [
  {
    image: require("@/assets/images/imitatepage/b2b2b-shopping-mall/goods01.png"),
    price: "￥386.90",
    originalPrice: "￥486.00",
  },
  {
    image: require("@/assets/images/imitatepage/b2b2b-shopping-mall/goods02.png"),
    price: "￥58.00",
    originalPrice: "￥86.00",
  },
  {
    image: require("@/assets/images/imitatepage/b2b2b-shopping-mall/goods03.png"),
    price: "￥6866.00",
    originalPrice: "￥7186.00",
  },
];

// 分类标签
const classifyTag = ["牛奶豆奶", "麦片谷粉", "奶茶咖啡", "饮料", "苏打水"];

// 商品展示list
const goodsList = [
  {
    title: "农夫山泉",
    goodsImg: require("@/assets/images/imitatepage/b2b2b-shopping-mall/goods04.png"),
  },
  {
    title: "哇哈哈",
    goodsImg: require("@/assets/images/imitatepage/b2b2b-shopping-mall/goods05.png"),
  },
];

// 置顶图标
const floatIcon = require("@/assets/images/imitatepage/b2b2b-shopping-mall/icon_float.png");

// tabbar
const tabbarData = [
  {
    icon: require("@/assets/images/imitatepage/b2b2b-shopping-mall/tabbar/icon_tab.png"),
    text: "首页",
    color: "#E13B29",
    isConvention: true,
    bold: 600,
  },
  {
    icon: require("@/assets/images/imitatepage/b2b2b-shopping-mall/tabbar/icon_tab2.png"),
    text: "分类",
    color: "#333333",
    isConvention: true,
  },
  {
    icon: require("@/assets/images/imitatepage/b2b2b-shopping-mall/tabbar/icon_tab3.png"),
    text: "积分旅游",
    color: "#333333",
    isConvention: true,
  },
  {
    icon: require("@/assets/images/imitatepage/b2b2b-shopping-mall/tabbar/icon_tab4.png"),
    text: "购物车",
    color: "#333333",
    isConvention: true,
  },
  {
    icon: require("@/assets/images/imitatepage/b2b2b-shopping-mall/tabbar/icon_tab5.png"),
    text: "我的",
    color: "#333333",
    isConvention: true,
  },
];
</script>

<style lang="scss" scoped>
.b2b2b-shopping-mall {
  position: relative;
  width: 100%;
  height: 100%;

  .wrap-content {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    box-sizing: border-box;
    overflow: hidden;
    // display: grid;
    // grid-template-rows: auto 1fr;
    padding: 0 2px;

    .nav_box {
      position: sticky;
      top: 0;
      left: 0;
    }

    .top-title {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
      justify-content: center;
      padding: 45px 16px 10px;
      background-color: #e13b29;
      .left {
        display: flex;
        align-items: center;
        color: white;
        font-size: 13px;
        img {
          width: 16px;
          height: 19px;
          margin-right: 5px;
        }
      }
      .search {
        display: flex;
        align-items: center;
        height: 30px;
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.4);
        padding-left: 50px;
        box-sizing: border-box;
        line-height: 30px;
        margin: 0 13px;

        font-size: 12px;
        img {
          width: 14px;
          height: 15px;
          margin-right: 6px;
        }

        span {
          color: white;
        }
      }
      .right {
        display: flex;
        margin-left: 10px;
        img {
          width: 19px;
          height: 17px;
        }
      }
    }

    // 分类导航
    .classify_box {
      position: relative;
      display: flex;
      white-space: nowrap;
      overflow-x: auto;
      height: 41px;
      width: 100vw;
      font-size: 14px;
      .classify_item {
        padding: 10px 15px;
        box-sizing: border-box;

        &:first-child {
          color: #e13b29;
          font-size: 16px;
        }
      }
      .line {
        position: absolute;
        bottom: 5px;
        width: 35px;
        height: 2px;
        left: 14px;
        background: #e13b29;
        border-radius: 3px;
      }
    }

    .content-box {
      overflow-y: auto;
      height: calc(100% - 126px - 60px);

      // 轮播图
      .banner_box {
        position: relative;
        height: 150px;
        img {
          width: 100%;
          height: 100%;
        }

        .round {
          position: absolute;
          left: 50%;
          bottom: 4px;
          display: flex;
          transform: translateX(-50%);
          align-items: center;
          .round_item {
            width: 6px;
            height: 6px;
            background-color: #fff;
            opacity: 0.6;
            margin-right: 8px;
            border-radius: 50%;

            &:last-child {
              background-color: #e13b29;
              width: 13px;
              border-radius: 3px;
            }
          }
        }

        .tips_box {
          position: absolute;
          top: 10px;
          left: 12px;
          display: flex;
          align-items: center;
          height: 21px;
          width: fit-content;
          background: #222222;
          opacity: 0.35;
          border-radius: 21px;
          line-height: 21px;
          padding: 0 5px;
          font-size: 10px;
          color: white;
          img {
            width: 12px;
            height: 12px;
            margin-right: 3px;
            margin-bottom: 2px;
          }
        }
      }

      // 分类
      .activity-alassify {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        padding: 15px 0 16px;
        background-color: #f5f5f5;
        position: relative;
        .activity-alassify_item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 12px;

          .img_box {
            position: relative;
            width: 44px;
            height: 44px;
            margin-bottom: 14px;
            img {
              width: 100%;
              height: 100%;
              &:nth-child(2) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 30px;
                height: 28px;
              }
            }
          }
        }

        > img {
          position: absolute;
          top: 24px;
          left: 210px;
          width: 27px;
          height: 31px;

          &:nth-last-child(2) {
            width: 29px;
            height: 29px;
            left: 345px;
          }

          &:last-child {
            position: absolute;
            top: 14px;
            left: 45px;
            width: 14px;
            height: 14px;
          }
        }
      }

      // 头条
      .headlines_box {
        height: 215px;
        padding: 12px;
        box-sizing: border-box;
        padding-top: 0;
        > .top {
          display: flex;
          align-items: center;
          height: 43px;
          line-height: 43px;
          border-bottom: 1px solid #dddddd;
          img {
            width: 14px;
            height: 12px;
            margin: 0 10px 0 6px;
          }
          span {
            font-size: 14px;
            color: #222222;

            &:last-child {
              font-size: 13px;
              color: #444444;
            }
          }
        }

        .bottom_box {
          display: flex;
          justify-content: space-between;

          margin-top: 14px;
          .left {
            width: 224px;
            height: 145px;
            background: #ecf5fe;
            border-radius: 6px;
            .title {
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #3c577c;
              padding: 8px 10px 10px;
              box-sizing: border-box;
              font-weight: 600;
              img {
                width: 15px;
                height: 14px;
                margin-right: 5px;
              }
            }

            .list_box {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-column-gap: 22px;
              grid-row-gap: 18px;
              width: 100%;
              padding: 17px;
              padding-top: 5px;
              box-sizing: border-box;
              font-size: 13px;
              font-weight: 600;
              color: #444444;

              .list_item {
                &:nth-child(2n) {
                  text-align: end;
                }
              }
            }
          }

          .right {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 113px;

            > img {
              width: 100%;
              height: 118px;
            }

            .top {
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #222222;
              font-weight: 600;
              img {
                width: 15px;
                height: 15px;
                margin-right: 5px;
              }
            }

            .text_box {
              position: absolute;
              bottom: 5px;
              left: 50%;
              transform: translateX(-50%);
              width: 106px;
              height: 16px;
              background: #ffffff;
              opacity: 0.6;
              border-radius: 8px;
              color: #222222;
              font-size: 8px;
              line-height: 16px;
              text-align: center;
            }
          }
        }
      }

      // 限时秒杀
      .seckill_box {
        height: 216px;
        background-color: #f5f5f5;
        padding: 10px 8px;

        .content {
          background-color: #fff;
          border-radius: 6px;
          height: 100%;
          padding: 14px 5px;
          box-sizing: border-box;
          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left {
              display: flex;
              align-items: center;
              color: #222222;
              font-size: 14px;
              font-weight: 600;

              img {
                width: 15px;
                height: 19px;
                margin-left: 20px;
                margin-right: 8px;
              }
            }

            .time {
              display: flex;
              align-items: flex-end;
              font-size: 11px;
              color: #222222;
              .time_item {
                display: inline-block;
                width: 17px;
                height: 17px;
                background: #e13b29;
                border-radius: 5px;
                color: white;
                text-align: center;
              }
              i {
                font-style: normal;
                margin: 0 6px;
              }
            }
            .right {
              display: flex;
              align-items: center;
              font-size: 12px;
              color: #666666;
              img {
                width: 7px;
                height: 13px;
                margin-left: 5px;
                margin-top: 2px;
              }
            }
          }

          .bottom {
            display: grid;
            grid-template-columns: auto 1fr auto;
            justify-items: center;
            padding: 0 5px;
            margin-top: 8px;

            .goods_item {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              font-size: 14px;
              width: 95px;
              color: #ea0000;
              font-weight: 600;
              img {
                width: 95px;
                height: 101px;
              }

              span {
                &:last-child {
                  font-size: 11px;
                  color: #666666;
                  text-decoration: line-through;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }

      // 商品列表展示
      .goods_box {
        .top {
          .title {
            padding: 18px 0 0 12px;
            font-size: 14px;
            color: #222222;
            font-weight: 600;
          }

          .classify_tag {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            padding: 0 13px;
            .classify_tag_item {
              display: flex;
              align-items: center;
              height: 24px;
              border: 1px solid #999999;
              border-radius: 12px;
              padding: 6px 8px;
              box-sizing: border-box;
              width: fit-content;
              font-size: 13px;
              color: #444444;
              line-height: 24px;
            }
          }
        }

        .goods_list {
          padding: 5px 15px 16px;
          box-sizing: border-box;
          .goods_item_show {
            color: #222222;
            font-size: 14px;
            border-bottom: 1px solid #dddddd;
            padding-bottom: 14px;

            .title {
              margin: 10px 0;
            }
            img {
              width: 100%;
              height: 154px;
            }
          }
        }
      }
    }

    .float_box {
      position: absolute;
      bottom: 64px;
      right: 14px;

      img {
        width: 43px;
        height: 43px;
      }
    }

    // tabbar
    .footer {
      position: sticky;
      bottom: 0;
      width: 100%;
      box-shadow: 2px 0px 8px 1px #e4e3e3;
      box-sizing: border-box;
      z-index: 2;
    }
  }
}
</style>
